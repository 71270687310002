import React, { useContext } from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import HeadingWithSub from "../components/HeadingWithSub";
import { StoreContext } from "../store";
import GalleryContainer from "../components/GalleryContainer";
import CollectionContainer from "../components/CollectionContainer";
import BinaryGridToggle from "../components/BinaryGridToggle";
import ParentRouteHandler from "../components/ParentRouteHandler";

const LookAtArtTogetherPage = ({ location, data }) => {
  const {
    forFamiliesExperienceIsGrid,
    toggleForFamiliesExperienceViewState,
  } = useContext(StoreContext);

  const artworks = data.allNodeArtPages.edges;

  return (
    <CollectionContainer
      data={data}
      render={({ renderList, dataForGalleryView }) => {
        return (
          <>
            <Seo title="Families Art Experience" />
            {location.state &&
            location.state.originalPath &&
            location.state.originalPath === "home" ? (
              <ParentRouteHandler title="Home" path="/" />
            ) : (
              <ParentRouteHandler title="For Families" path="/for-families" />
            )}
            <div className="heading">
              <HeadingWithSub
                main={`Let's Look at Art Together`}
                subTitle={`For families`}
              />
            </div>
            <div className={`toggle-wrap`}>
              <BinaryGridToggle
                isGrid={forFamiliesExperienceIsGrid}
                handler={() => toggleForFamiliesExperienceViewState()}
              />
            </div>
            {forFamiliesExperienceIsGrid ? (
              <GalleryContainer
                data={dataForGalleryView(artworks)}
                queryParams={"kids=1"}
              />
            ) : (
              renderList(artworks, "kids=1")
            )}
          </>
        );
      }}
    />
  );
};

export default LookAtArtTogetherPage;

export const query = graphql`
  query {
    allNodeArtPages(
      filter: {
        field_on_view: { eq: true }
        field_include_families_questions: { eq: true }
      }
      sort: { fields: [field_sort_order_weight], order: ASC }
    ) {
      edges {
        node {
          ...ArtPages
        }
      }
    }
  }
`;
